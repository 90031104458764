import Message from "../component/message";
import History from "../RouteHistory";
import {getI18n} from "react-i18next";

const RedirectToError = message => {

  // route to error
  History.push({
    pathname: '/error',
    state: {
      message: message
    }
  })

}

// catch error
const SendMessage = error => {

  // get error message from Api
  if (error.response && error.response.data && error.response.data.error) {
    let errCode = error.response.data.error

    switch (errCode) {

      case "reservation_not_found":
        RedirectToError(getI18n().t('error.permission_error'))
        break;

      case "reservation_expired":
        RedirectToError(getI18n().t('error.expired_error'))
        break;

      case "reservation_not_start":
        RedirectToError(getI18n().t('error.meeting_not_start_error'))
        break;

      case "invalid_token":
        RedirectToError(getI18n().t('error.permission_error'))
        break;

      case "access_denied":
        RedirectToError(getI18n().t('error.permission_error'))
        break;

      case "invalid_parameter":
        Message.info(getI18n().t('error.invalid_parameter_error'))
        break;

      case "server_error":
        Message.info(getI18n().t('error.server_error'))
        break;

      default:
        Message.info(getI18n().t('error.server_error'))
        break;
    }
  }

  // get net work message
  else if (error.message === "Network Error") {
    Message.info(getI18n().t('error.network_error'));
  }

  // Exception
  else {
    Message.info(getI18n().t('error.server_error'));
  }

}
export {SendMessage, RedirectToError};