import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from "i18next";
import enUsTrans from "./en-US/translation.json";
import jaJPTrans from "./ja-JP/translation.json";
import {
  initReactI18next
} from 'react-i18next';

i18n.use(LanguageDetector)
.use(initReactI18next) //init i18next
.init({
  // check browser navigator
  detection: {
    order: ['navigator']
  },
  // ja use ja-JP, other use en-US ( browser navigator )
  resources: {
    en: {
      translation: enUsTrans,
    },
    ja: {
      translation: jaJPTrans,
    },
  },
  // default language
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
}).then()
