import axios from 'axios'
import {RedirectToError, SendMessage} from "./HandleError";
import {LoginStorage} from "./GlobalService";
import JSZip from 'jszip'
import {getI18n} from "react-i18next";
import Message from "../component/message";

const baseURL = process.env.REACT_APP_BASE_API_URL

// 予約状態確認API
const CheckReservations = (reservationId, onSuccess, onServerError,
    onNetworkError) => {

  // axios request
  axios.get(
      // URL
      baseURL + "reservations/" + reservationId + "/status",
      {
        mode: "cors"
      }
  )
  // request status equals 200
  .then(res => {
    onSuccess(res)
  })
  // request status not equal to 200
  .catch(error => {
    if ((error.response && error.response.data && error.response.data.error
        && error.response.data.error === "server_error")) {
      onServerError()
    } else if (error.message === "Network Error") {
      onNetworkError()
    } else {
      SendMessage(error)
    }
  })

}

// ログインAPI
const LoginCheck = (reservationId, username, userPwd, onSuccess,
    onInvalidCredentialsDetected, onMaxOfParticipants,
    onFinally) => {

  // axios request
  axios.post(
      // URL
      baseURL + "login",
      // data
      {
        "reservation_id": reservationId,
        "user_id": username,
        "password": userPwd
      },
      {
        mode: "cors"
      }
  )

  // request status equals 200
  .then(res => {
    onFinally && onFinally()
    onSuccess(res)
  })

  // request status not equal to 200
  .catch(error => {
    onFinally && onFinally()
    // get error message from Api
    if (error.response && error.response.data && error.response.data.error) {
      let errCode = error.response.data.error
      switch (errCode) {
        case "invalid_credentials":
          onInvalidCredentialsDetected();
          break;
        case "host_user_limit":
          Message.info(getI18n().t("login.max_num_of_host_message"))
          break;
        case "guest_user_limit":
          Message.info(getI18n().t("login.max_num_of_guest_message"))
          break;
        case "all_user_limit":
          onMaxOfParticipants();
          break;
        default:
          SendMessage(error)
      }
    } else {
      SendMessage(error)
    }
  })

}

// 予約情報取得API
const LoadImage = (onSuccess, onFinally) => {

  let token = LoginStorage.getToken()
  // axios request
  axios.get(
      //URL
      baseURL + "reservation",
      {
        mode: 'cors',
        headers: {
          "x-auth-token": token
        }
      }
  )

  // request status equals 200
  .then(res => {
    // get res.data
    const {guest_user_image_url_list, host_user_image_url_list} = res.data;
    // set each item a key value
    guest_user_image_url_list.map((imageUrl, index) => {
      return (
          guest_user_image_url_list[index] = {
            url: baseURL + imageUrl["image_url"],
            key: imageUrl.key,
            selected: false,
          }
      )
    })
    // set each item a key value
    host_user_image_url_list.map((imageUrl, index) => {
      return (
          host_user_image_url_list[index] = {
            url: baseURL + imageUrl["image_url"],
            key: imageUrl.key,
            selected: false,
          }
      )
    })
    onFinally && onFinally()
    onSuccess(guest_user_image_url_list, host_user_image_url_list)
  })

  // request status not equal to 200
  .catch(error => {
    onFinally && onFinally()
    SendMessage(error)
  })

}

//ゲストユーザー画像アップロードAPI
const UploadImage = (file, onSuccess, onMaxOfGuestImage, onFinally) => {

  let token = LoginStorage.getToken()

  // Initial FormData
  const formData = new FormData();
  formData.append("file", file);

  axios.post(baseURL + "guest/images", formData, {
    mode: 'cors',
    headers: {"x-auth-token": token}
  })

  // request status equals 200
  .then(res => {
    onFinally && onFinally()
    onSuccess(res)
  })

  // request status not equal to 200
  .catch(error => {
    onFinally && onFinally()
    if (error.response && error.response.data && error.response.data.error) {
      if (error.response.data.error === "guest_user_image_limit") {
        onMaxOfGuestImage()
      } else {
        SendMessage(error)
      }
    } else {
      SendMessage(error)
    }
  })

}

//携帯電話画像アップロードAPI
const MobileUploadImage = (file, mobileToken, onSuccess, onFailed,
    onFinally) => {

  let token = mobileToken

  // Initial FormData
  const formData = new FormData();
  formData.append("file", file);

  axios.post(baseURL + "guest/images", formData, {
    mode: 'cors',
    headers: {"x-auth-token": token}
  })

  // request status equals 200
  .then(res => {
    onFinally && onFinally()
    onSuccess(res)
  })

  // request status not equal to 200
  .catch(error => {
    onFinally && onFinally()
    onFailed(error)
  })

}

const CACHED_IMAGE_BLOBS = {};
const CACHED_ERROR_IMAGE_URLS = [];
// get catchImage (if haven't cached image,won't download image again)
// used for load image
const GetImageBlobUrl = (url, onSuccess, onFailed, onFinally) => {
  let token = LoginStorage.getToken()
  if (CACHED_IMAGE_BLOBS[url]) {
    onFinally && onFinally()
    onSuccess(CACHED_IMAGE_BLOBS[url]);
    return
  }
  // control sortable image,won't let image download again
  if (CACHED_ERROR_IMAGE_URLS.includes(url)) {
    onFinally && onFinally()
    onFailed && onFailed()
    return
  }
  axios.get(
      url,
      {
        headers: {
          "accept": "image/*",
          "x-auth-token": token
        },
        responseType: "blob",
      }
  )
  // request status equals 200
  .then(res => {
    let blobUrl = URL.createObjectURL(res.data)
    // cache image
    CACHED_IMAGE_BLOBS[url] = blobUrl;
    onFinally && onFinally()
    onSuccess(blobUrl)
  })
  .catch(() => {
    // cache image
    CACHED_ERROR_IMAGE_URLS.push(url)
    onFinally && onFinally()
    onFailed && onFailed()
  })
}

const DownloadBlob = url => {
  let token = LoginStorage.getToken()
  return axios.get(url,
      {
        headers: {
          "accept": "image/*",
          "x-auth-token": token
        },
        responseType: "blob"
      })
  .then(res => {
    return res.data
  });
};

const DownloadAllBlob = urls => {
  return Promise.all(urls.map(url => DownloadBlob(url.url)))
}

// zip blobを生成
const GenerateZipBlob = async (urls) => {
  let exitedURLs = urls.filter(item =>
      (CACHED_IMAGE_BLOBS[item])
  )
  let needDownLoadURLs = urls.filter(item =>
      (!CACHED_IMAGE_BLOBS[item])
  )
  const zip = new JSZip();
  const folder = zip.folder();
  let cardNum = 0;
  if (needDownLoadURLs.length > 0) {
    let blobs = await DownloadAllBlob(needDownLoadURLs)
    blobs.forEach(blob => {
      cardNum = cardNum + 1;
      const name = `card${cardNum}.jpg`;
      folder.file(name, blob);
    })
  }
  if (exitedURLs.length > 0) {
    exitedURLs.forEach(item => {
      cardNum = cardNum + 1;
      const name = `card${cardNum}.jpg`;
      const content = fetch(CACHED_IMAGE_BLOBS[item]).then(r => r.blob());
      folder.file(name, content);
    })
  }
  return zip.generateAsync({type: 'blob'}); // デフォルトで無圧縮
};

// ログアウトAPI
const LogoutCheck = (onSuccess, onStart, onFinally) => {

  let token = LoginStorage.getToken()

  onStart && onStart()
  // axios request
  axios.post(
      // URL
      baseURL + "logout",
      {},
      {
        mode: "cors",
        headers: {
          "x-auth-token": token
        },
      }
  )
  // request status equals 200
  .then(res => {
    if (res.data && res.data.status === "ok") {
      onFinally && onFinally()
      onSuccess(res)
    } else {
      onFinally && onFinally()
      window.sessionStorage.clear()
      RedirectToError(getI18n().t("error.over_meeting_time_error"))
    }
  })
  // request status not equal to 200
  .catch(error => {
    onFinally && onFinally()
    SendMessage(error);
  })
}

// get catchImage(if haven't cached image,download image again)
// used for download,enlarge
const CatchBlobImageUrl = (url, onSuccess, onFailed, onFinally) => {
  let token = LoginStorage.getToken()
  if (CACHED_IMAGE_BLOBS[url]) {
    onFinally && onFinally()
    onSuccess(CACHED_IMAGE_BLOBS[url]);
    return
  }
  axios.get(
      url,
      {
        headers: {
          "accept": "image/*",
          "x-auth-token": token
        },
        responseType: "blob",
      }
  )
  // request status equals 200
  .then(res => {
    let blobUrl = URL.createObjectURL(res.data)
    onFinally && onFinally()
    onSuccess(blobUrl)
  })
  .catch(() => {
    onFinally && onFinally()
    onFailed && onFailed()
  })
}

export {
  CheckReservations,
  LoginCheck,
  LoadImage,
  UploadImage,
  MobileUploadImage,
  GetImageBlobUrl,
  GenerateZipBlob,
  LogoutCheck,
  CatchBlobImageUrl
}
