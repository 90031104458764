import React from 'react';
import styles from "./index.module.css"
import {withTranslation} from "react-i18next";

class Index extends React.Component {

  render() {

    // get error message
    const state = this.props.history.location.state
    const message = (state && state.message) || this.props.t(
        'error.permission_error')

    return (
        <div className={styles.errorContainer}>
          <div className={styles.content}>
            <p>{message}</p>
          </div>
        </div>
    );

  }
}

export default withTranslation()(Index);