import React from "react";
import styles from "./index.module.css"
import {Spinner} from "react-bootstrap";

// Component of provide the mask and loading icon
export default ({visible, text}) => {

  return (
      visible ?
          <div className={styles.mask}>
            <div className={styles.spinContainer}>
              <Spinner
                  animation="border" variant="light"
              />
              <div className={styles.text}>
                {text}
              </div>
            </div>
          </div> : null
  )

}