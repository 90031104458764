import React, { Component } from 'react'
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { LoginCheck } from "../../service/ApiCollection";
import Spin from "../../component/spin/index"
import { LoginStorage } from "../../service/GlobalService";
import { RedirectToError } from "../../service/HandleError";
import styles from "./index.module.css"
import LoginModal from "../../component/common-modal"
import { withTranslation } from "react-i18next";

class Index extends Component {

  constructor(props) {

    super(props);
    this.historyState = props.location.state
    this.state = {
      logging: false,
      wrongMessageVisible: false,
      maxNumOfParticipants: false,
      maxNumOfCardsModalVisible: false,
      unsetCardModalVisible: false,
      userIdIsInvalid: false,
      passwordIsInvalid: false,
    }
  }

  componentDidMount() {

    // if historyState not existed
    if (!this.historyState) {
      // route to error
      RedirectToError(this.props.t('error.permission_error'))
    }
  }

  checkUserId = (userId) => {
    let result = true
    if (!userId || !userId.trim()) {
      this.setState({ userIdIsInvalid: true })
      result = false
    } else {
      this.setState({ userIdIsInvalid: false })
    }
    return result
  }

  checkPassword = (password) => {
    let result = true
    if (!password) {
      this.setState({ passwordIsInvalid: true })
      result = false
    } else {
      this.setState({ passwordIsInvalid: false })
    }
    return result
  }

  checkAllFormValue = (form) => {

    let userIdResult = this.checkUserId(form["userId"].value)
    let passwordResult = this.checkPassword(form["password"].value)

    return userIdResult && passwordResult
  }

  submit = event => {

    this.loginClicked = true;
    event.preventDefault();
    const form = event.currentTarget;

    this.setState({
      wrongMessageVisible: false
    })

    // empty userId or empty password
    if (!this.checkAllFormValue(form)) {
      event.stopPropagation();
    } else {
      this.setState({
        logging: true
      })
      // Api
      LoginCheck(this.historyState.reservationId, form["userId"].value.trim(),
        form["password"].value,

        // successful request
        res => {

          // window.sessionStorage save Login message
          LoginStorage.saveLogin(res.data.token, res.data.role,
            this.historyState.reservationId)

          if (res.data.status === "ok") {
            this.redirect();
          } else if (res.data.status === "host_user_no_image") {
            this.setState({
              unsetCardModalVisible: true
            })
          } else if (res.data.status === "host_user_image_limit") {
            this.setState({
              maxNumOfCardsModalVisible: true,
            })
          }

        },
        // invalid_credentials
        () => {
          // show wrong_message
          this.setState({
            wrongMessageVisible: true,
          })
        },
        // all_user_limit
        () => {
          // show max_num_of_participants_message
          this.setState({
            maxNumOfParticipants: true,
          })
        },
        () => {
          this.setState({
            logging: false
          })
        }
      )
    }
  }

  redirect = () => {
    if (!this.state.unsetCardModalVisible
      && !this.state.maxNumOfCardsModalVisible) {
      let redirectTo;
      switch (LoginStorage.getRole()) {
        case "guest":
          redirectTo = "guest"
          break;
        case "host":
          redirectTo = "host"
          break;
        default:
          redirectTo = null
          break
      }

      if (redirectTo) {
        // redirect to page
        this.props.history.push({
          pathname: "/" + redirectTo
        })
      } else {
        // route to error
        RedirectToError(this.props.t('error.permission_error'))
      }
    }
  }

  render() {

    const { t } = this.props

    return (

      <Container>

        <Spin visible={this.state.logging} text={t('common.logging')} />

        <LoginModal
          show={this.state.unsetCardModalVisible}
          onHide={() => {
            this.setState({ unsetCardModalVisible: false }, () => {
              // after click will redirect to gallery
              this.redirect()
            });
          }}
          text={t('login.unset_card_message')}
        />

        <LoginModal
          show={this.state.maxNumOfCardsModalVisible}
          onHide={() => {
            this.setState({ maxNumOfCardsModalVisible: false }, () => {
              // after click will redirect to gallery
              this.redirect()
            })
          }}
          text={t('login.max_num_of_cards_message')}
        />

        <Row>
          <Col lg={{ span: 12 }}>
            <h3 align="center" style={{ paddingTop: 15 }}>
              {t('login.title')}
            </h3>
          </Col>
        </Row>

        <Row className={styles.wrongUserMessage} id={"message"}>
          <Col style={{ textAlign: "center" }}>
            <strong>
              {this.state.wrongMessageVisible ? t('login.wrong_message')
                : null}
              {this.state.maxNumOfParticipants ? t(
                'login.max_num_of_participants_message') : null}
            </strong>
          </Col>
        </Row>

        <Form noValidate
          onSubmit={this.submit}>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Form.Group controlId="userId">
                <Form.Label>{t('login.userid')}</Form.Label>
                <Form.Control
                  isInvalid={this.loginClicked && this.state.userIdIsInvalid}
                  isValid={this.loginClicked && !this.state.userIdIsInvalid}
                  type="text"
                  placeholder=""
                  disabled={this.state.maxNumOfParticipants}
                  onChange={e => {
                    this.checkUserId(e.target.value)
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {t('login.empty_userid_message')}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Form.Group controlId="password">
                <Form.Label>{t('login.password')}</Form.Label>
                <Form.Control
                  type="password"
                  isInvalid={this.loginClicked && this.state.passwordIsInvalid}
                  isValid={this.loginClicked && !this.state.passwordIsInvalid}
                  placeholder=""
                  disabled={this.state.maxNumOfParticipants}
                  onChange={e => {
                    this.checkPassword(e.target.value)
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {t('login.empty_password_message')}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row style={{ marginTop: "2%" }}>
            <Col lg={{ span: 12 }}>
              <div align="center">
                <Button type={"submit"}
                  disabled={this.state.maxNumOfParticipants}
                  className={styles.button}>{t(
                    'login.login_text')}</Button>
              </div>
            </Col>
          </Row>
        </Form>

      </Container>
    );
  }
}

export default withTranslation()(Index);