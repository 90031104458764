import React, {useState} from "react";
import {UploadImage} from "../../service/ApiCollection";
import Message from "../message";
import Spin from "../spin";
import styles from "./index.module.css"
import QRCode from "qrcode.react";
import {useTranslation} from "react-i18next";
import {CheckImageType, Compress, ResetBlobDpi} from "../../service/Utils"
import {LoginStorage} from "../../service/GlobalService";
import UploadModal from "../common-modal";
import {CloseIcon} from "../icon/Icon";

// Component of receive files that user dropped
export default ({onClickCloseIcon}) => {
  const [dropping, setDropping] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [illegalImageModalVisible, setIllegalImageModalVisible] = useState(
      false);
  const [maxNumOfImageModalVisible, setMaxNumOfImageModalVisible] = useState(
      false);
  const {t} = useTranslation()
  const onDropImage = event => {
    // set background dimgray
    setDropping(false);
    // get dropped file by dataTransfer (drag event)
    // limited the max file
    let file = event.dataTransfer.files[0]
    // check file extension
    CheckImageType(file, () => {

      // uploading アプロード中
      setUploading(true)

      // test compress time and upload time
      let start = Date.now()
      // 画像の縦横比は変更しなくで、長辺は1075以下に縮小する。
      Compress(file)
      .then(compressedBlob => {
        return ResetBlobDpi(compressedBlob)
      })
      // 300dpi固定に変更する
      .then(updatedBlob => {
        // create File by blob
        let compressFile = new File([updatedBlob], file.name,
            {type: "image/jpeg"});
        // log compress information
        console.log(t('drop_zone.file_before_compress'), file)
        console.log(
            t('drop_zone.compress_time') + (Date.now() - start) / 1000
            + "s")
        console.log(t('drop_zone.file_after_compress'), compressFile)
        // upload
        upload(compressFile)
      })
      .catch(() => {
        setUploading(false)
        Message.info(t('common.failed_read'))
      })

    }, () => {
      setIllegalImageModalVisible(true)
    })
  }

  // the function of send a request Api
  const upload = file => {
    let start = Date.now()
    UploadImage(
        file, () => {
          setUploading(false)
          Message.info(t('drop_zone.success_upload'))
          // log upload time
          console.log(
              t('drop_zone.upload_time') + (Date.now() - start) / 1000 + "s")
        },
        () => {
          setMaxNumOfImageModalVisible(true)
        },
        () => {
          setUploading(false)
        }
    )
  }

  return <>
    <Spin visible={uploading} text={t('common.uploading')}/>

    <UploadModal
        // upload an illegal image
        show={illegalImageModalVisible}
        onHide={() => {
          setIllegalImageModalVisible(false)
        }}
        text={t('drop_zone.illegal_image_type')}
    />

    <UploadModal
        // uploaded image over max size
        show={maxNumOfImageModalVisible}
        onHide={() => {
          setMaxNumOfImageModalVisible(false)
        }}
        text={t('drop_zone.max_num_of_upload_image')}
    />

    <div className={styles.dropzone}
        // Browser compatible (check firefox after change)!!!
         ref={dom => {
           if (dom) {
             // Drop file
             dom.ondrop = event => {
               event.stopPropagation();
               event.preventDefault();
               if (event.dataTransfer.files.length > 0) {
                 // upload
                 onDropImage(event);
               }
             }
             // Drag files to enter
             dom.ondragenter = event => {
               event.stopPropagation();
               event.preventDefault();
               if (event.dataTransfer.types.includes("Files")) {
                 setDropping(true)
               }
             }
             // Drag files to leave
             dom.ondragleave = event => {
               event.stopPropagation();
               event.preventDefault();
               setDropping(false)
             }
             // Drag files and retained in drop zone (effect chrome)
             dom.ondragover = event => {
               event.preventDefault()
             }
           }
         }}
         style={dropping ? {background: "dimgray"} : null}
    >
      <div className={styles.dropFont}>
        {t('drop_zone.text')}
      </div>
      <div className={styles.qrcode}>
        <QRCode
            value={window.location.origin + "/upload/?token="
            + LoginStorage.getToken()}
            bgColor={"transparent"}/>
      </div>
      <div className={styles.closeIcon} onClick={onClickCloseIcon}>
        <CloseIcon/>
      </div>
    </div>
  </>
}
