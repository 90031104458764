let sessionStorage = window.sessionStorage;

let LoginStorage = {

  //save login message include token、role
  saveLogin(token, role, reservationId) {
    sessionStorage["token"] = token
    sessionStorage["role"] = role
    sessionStorage["reservationId"] = reservationId
  },

  //get token from window.sessionStorage
  getToken() {
    if (sessionStorage["token"] === undefined) {
      return null;
    }
    return sessionStorage["token"];
  },

  //get loginRole from window.sessionStorage
  getRole() {
    if (sessionStorage["role"] === undefined) {
      return null;
    }
    return sessionStorage["role"];
  },

  //get reservationId from window.sessionStorage
  getReservationId() {
    if (sessionStorage["reservationId"] === undefined) {
      return null;
    }
    return sessionStorage["reservationId"];
  },

  clearStorage() {
    sessionStorage.removeItem("token")
    sessionStorage.removeItem("role")
    sessionStorage.removeItem("reservationId")
  }
};

export {LoginStorage}