import React from "react";
import styles from "./index.module.css"
import Message from "../../component/message";
import {
  CheckImageType,
  Compress,
  getQueryParam,
  ResetBlobDpi
} from "../../service/Utils"
import {MobileUploadImage} from "../../service/ApiCollection";
import {RedirectToError} from "../../service/HandleError";
import {withTranslation} from "react-i18next";
import Spin from "../../component/spin";
import UploadModal from "../../component/common-modal";
import {Image} from "react-bootstrap";

class Index extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // spinner visible
      uploading: false,
      // submit disable
      buttonDisable: true,
      // max
      maxNumOfImageModalVisible: false,
      // Does the file type belong to 'image/jpeg'
      isRightImageType: true
    }
    this.file = null
    this.token = null
  }

  componentDidMount() {

    // get token
    this.token = getQueryParam("token")

    if (!this.token) {
      // route to error
      RedirectToError(this.props.t('error.permission_error'))
    }
  }

  updateImageDisplay = event => {
    // get file after input changed
    let file = event.target.files[0]

    if (file) {
      CheckImageType(file, () => {
        // right file type
        this.file = file
        this.setState({
          buttonDisable: false,
          isRightImageType: true
        })
      }, () => {
        // wrong file type
        this.file = file
        this.setState({
          buttonDisable: true,
          isRightImageType: false
        })
      })
    } else {
      // select nothing
      this.file = null
      this.setState({
        buttonDisable: true
      })
    }
  }

  // the function of send a request Api
  uploadImage = file => {
    MobileUploadImage(
        file, this.token, () => {
          this.setState({
            buttonDisable: true
          })
          // uploaded succeed
          Message.info(this.props.t('mobile_upload.success_upload'))
        }, error => {
          if (error.response && error.response.data
              && error.response.data.error) {
            // switch error code
            let errCode = error.response.data.error

            switch (errCode) {

              case "expired_token":
                Message.info(this.props.t('error.permission_error'))
                break;

              case "invalid_token":
                Message.info(this.props.t('error.permission_error'))
                break;

              case "reservation_not_found":
                Message.info(this.props.t('error.permission_error'))
                break;

              case "guest_user_image_limit":
                this.setState({
                  maxNumOfImageModalVisible: true
                })
                break;

              default:
                Message.info(this.props.t('error.server_error'))
                break;
            }
          }

          // get net work message
          else if (error.message === "Network Error") {
            Message.info(this.props.t('error.network_error'));
          }

          // Exception
          else {
            Message.info(this.props.t('error.server_error'));
          }

        }, () => {
          this.setState({
            uploading: false
          })
        }
    )
  }

  checkImage = () => {
    if (this.file) {
      this.setState({
        uploading: true
      })
      // 画像の縦横比は変更しなくで、長辺は1075以下に縮小する。
      Compress(this.file)
      .then(compressedBlob => {
        return ResetBlobDpi(compressedBlob)
      })
      // 300dpi固定に変更する
      .then(updatedBlob => {
        // create File by blob
        let compressFile = new File([updatedBlob], this.file.name,
            {type: "image/jpeg"});
        // upload
        this.uploadImage(compressFile)
      })
      .catch(() => {
        this.setState({
          uploading: false
        })
        Message.info(this.props.t('common.failed_read'))
      })
    }
  }

  render() {
    const {t} = this.props
    return (
        <>
          <UploadModal
              show={this.state.maxNumOfImageModalVisible}
              onHide={() => {
                this.setState({maxNumOfImageModalVisible: false})
              }}
              text={t('drop_zone.max_num_of_upload_image')}
          />
          <div className={styles.formContainer}>
            <Spin visible={this.state.uploading} text={t('common.uploading')}/>
            <form className={styles.form}>
              <div className={styles.preview}>
                {this.file ?
                    <ol>
                      <li>
                        {this.state.isRightImageType ?
                            <Image
                                src={URL.createObjectURL(this.file)}
                                className={styles.image}
                            /> :
                            <p>{this.props.t('mobile_upload.invalid_selected',
                                {name: this.file.name})}
                            </p>
                        }
                      </li>
                    </ol>
                    : <p>{t('mobile_upload.not_selected_file')}</p>
                }
              </div>
              <div>
                <label htmlFor="image_uploads">{t(
                    'mobile_upload.select_file_message')}</label>
                <input type="file" id="image_uploads" name="image_uploads"
                       accept="image/*" style={{visibility: "hidden", width: 0}}
                       onChange={this.updateImageDisplay}
                />
              </div>
              <div>
                <button onClick={event => {
                  event.preventDefault();
                  this.checkImage()
                }} disabled={this.state.buttonDisable}>{t(
                    'mobile_upload.submit')}</button>
              </div>
            </form>
          </div>
        </>
    );
  }
}

export default withTranslation()(Index)