import React, {useCallback} from "react";
import {Modal, Button} from "react-bootstrap";
import styles from "./index.module.css"
import {useTranslation} from "react-i18next";

// the modal component of show dialog ( alert or confirm )
export default ({show, onHide, onConfirm, text, cancelText, confirmText}) => {
  const {t} = useTranslation()

  // after click confirm
  const onCertain = useCallback(() => {
    onConfirm && onConfirm()
  }, [onConfirm])

  // after click cancel
  const onClose = useCallback(() => {
    onHide && onHide()
  }, [onHide])

  return (
      <Modal show={show} onHide={onClose}>
        <Modal.Body>
          <div className={styles.text}>
            {text}
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          {
            // judge button css style
            onConfirm || confirmText ?
                <>
                  <Button variant="secondary" onClick={onClose}>{cancelText
                      ? cancelText : t('common.no')}</Button>
                  <Button variant="primary" onClick={onCertain}>{confirmText
                      ? confirmText : t('common.yes')}</Button>
                </> : <Button variant="primary" onClick={onClose}>{cancelText
                ? cancelText : t('common.close')}</Button>
          }
        </Modal.Footer>
      </Modal>
  )
}