import React from 'react';
import ReactDOM from 'react-dom';
import './locales/i18n'
// Don't change the order of import packages(effect css styles)
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import './index.css';

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
