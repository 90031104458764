import React from "react";
import LogoutImage from "../../static/logout.png"
import {Image} from "react-bootstrap";

// the storage of Icon
export function CloseIcon() {
  return <svg viewBox="0 0 1024 1024" width="32" height="32">
    <path
        d="M818.1 872.1c-15.4 0-30.7-5.9-42.4-17.6l-613-612.9c-23.4-23.4-23.4-61.4 0-84.9 23.4-23.4 61.4-23.4 84.9 0l612.9 612.9c23.4 23.4 23.4 61.4 0 84.9a59.914 59.914 0 0 1-42.4 17.6z"
        fill="" p-id="6902"/>
    <path
        d="M205.1 872.1c-15.4 0-30.7-5.9-42.4-17.6-23.4-23.4-23.4-61.4 0-84.9l612.9-612.9c23.4-23.4 61.4-23.4 84.9 0 23.4 23.4 23.4 61.4 0 84.9L247.6 854.5c-11.7 11.7-27.1 17.6-42.5 17.6z"
        fill="" p-id="6903"/>
  </svg>
}

export function LgCloseIcon() {
  return <svg viewBox="0 0 1024 1024" width="40" height="40">
    <path
        d="M818.1 872.1c-15.4 0-30.7-5.9-42.4-17.6l-613-612.9c-23.4-23.4-23.4-61.4 0-84.9 23.4-23.4 61.4-23.4 84.9 0l612.9 612.9c23.4 23.4 23.4 61.4 0 84.9a59.914 59.914 0 0 1-42.4 17.6z"
        fill="" p-id="6902"/>
    <path
        d="M205.1 872.1c-15.4 0-30.7-5.9-42.4-17.6-23.4-23.4-23.4-61.4 0-84.9l612.9-612.9c23.4-23.4 61.4-23.4 84.9 0 23.4 23.4 23.4 61.4 0 84.9L247.6 854.5c-11.7 11.7-27.1 17.6-42.5 17.6z"
        fill="" p-id="6903"/>
  </svg>
}

export function DownLoadIcon() {
  return <svg viewBox="0 0 1024 1024" width="40" height="40">
    <path
        d="M531.36384 796.86144a30.72 30.72 0 0 1-43.84768 0l-266.0096-271.02208C202.43456 506.40384 216.20224 473.6 243.4304 473.6h532.0192c27.22816 0 40.99584 32.80384 21.92384 52.23936l-266.0096 271.02208zM153.6 921.6a30.72 30.72 0 0 1 30.72-30.72h655.36a30.72 30.72 0 0 1 30.72 30.72v20.48a30.72 30.72 0 0 1-30.72 30.72H184.32a30.72 30.72 0 0 1-30.72-30.72v-20.48z"
    />
    <path
        d="M583.68 51.2a30.72 30.72 0 0 1 30.72 30.72v532.48a30.72 30.72 0 0 1-30.72 30.72H440.32a30.72 30.72 0 0 1-30.72-30.72V81.92a30.72 30.72 0 0 1 30.72-30.72h143.36z"
    />
  </svg>
}

export function ListIcon() {
  return <svg viewBox="0 0 1024 1024" width="40" height="40">
    <path
        d="M800 953.6H224c-32 0-57.6-25.6-57.6-57.6V128C169.6 96 192 73.6 224 73.6h576c32 0 57.6 25.6 57.6 57.6v768c0 28.8-25.6 54.4-57.6 54.4zM224 121.6c-3.2 0-6.4 3.2-6.4 6.4v768c0 3.2 3.2 9.6 9.6 9.6h576c3.2 0 9.6-3.2 9.6-9.6V128c0-3.2-3.2-9.6-9.6-9.6H224z"
    />
    <path
        d="M716.8 620.8H307.2c-12.8 0-25.6-9.6-25.6-25.6s9.6-25.6 25.6-25.6h409.6c12.8 0 25.6 9.6 25.6 25.6s-12.8 25.6-25.6 25.6zM716.8 793.6H307.2c-12.8 0-25.6-9.6-25.6-25.6s9.6-25.6 25.6-25.6h409.6c12.8 0 25.6 9.6 25.6 25.6s-12.8 25.6-25.6 25.6zM716.8 444.8H307.2c-12.8 0-25.6-9.6-25.6-25.6s9.6-25.6 25.6-25.6h409.6c12.8 0 25.6 9.6 25.6 25.6s-12.8 25.6-25.6 25.6zM716.8 278.4H307.2c-12.8 0-25.6-9.6-25.6-25.6s9.6-25.6 25.6-25.6h409.6c12.8 0 25.6 9.6 25.6 25.6s-12.8 25.6-25.6 25.6z"
    />
  </svg>
}

export function LogOutIcon() {
  return <Image src={LogoutImage} width={40} height={40}/>
}