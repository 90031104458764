import React from 'react';
import {Spinner} from "react-bootstrap";
import {CheckReservations} from "../../service/ApiCollection";
import {RedirectToError} from "../../service/HandleError";
import {withTranslation} from "react-i18next";
import styles from "./index.module.css"
import {getQueryParam} from "../../service/Utils";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wrongMessageVisible: false,
    }
    this.wrongMessage = null
  }

  componentDidMount() {

    let reservationId = getQueryParam("reservation")

    if (!reservationId) {
      // route to error
      RedirectToError(this.props.t('error.permission_error'))
    } else {
      CheckReservations(reservationId, () => {
            // route to login
            this.props.history.push({
              pathname: '/login',
              // set state["reservationId"]
              state: {
                reservationId: reservationId,
              }
            })
          },
          // serverError replace spinner
          () => {
            this.wrongMessage = this.props.t('error.server_error')
            this.setState({
              wrongMessageVisible: true,
            })
          },
          // networkError replace spinner
          () => {
            this.wrongMessage = this.props.t('error.network_error')
            this.setState({
              wrongMessageVisible: true,
            })
          }
      )
    }
  }

  render() {
    return (
        this.state.wrongMessageVisible ?
            // Spinner
            <div className='errorContainer'>
              <div className='errorContent'><p>{this.wrongMessage}</p>
              </div>
            </div> : <Spinner animation="border" className={styles.spinner}/>
    );
  }

}

export default withTranslation()(Index);