import React, {Component} from 'react';
import {LogoutCheck, LoadImage} from "../../service/ApiCollection";
import Spin from "../../component/spin";
import {SecurityPage} from "../SecurityPage";
import SortableImageContainer from "../../component/gallery-container";
import CardsListModal from "../../component/cards-list-modal";
import Constants from "../../setting/Constants";
import styles from "../CommonStyle.module.css";
import Toolbar from "../../component/toolbar";
import {withTranslation} from "react-i18next";
import LogoutModal from "../../component/common-modal";
import {LoginStorage} from "../../service/GlobalService";

class Index extends Component {

  constructor(props) {

    super(props);

    this.state = {
      // gallery
      guestImageList: null,
      modalList: [],
      // spin
      loading: false,
      logout: false,
      // modal
      cardsListModalVisible: false,
      logoutModalVisible: false,

    }
  }

  componentDidMount() {

    this.setState({
      loading: true
    })

    // send a request
    LoadImage(
        // successful request
        (guestList, hostList) => {
          this.setImageList(guestList, hostList);
        },

        // finally set loading:false
        () => {
          this.setState({
            loading: false
          }, () => {
            // add setInterval (send a request per 5s)
            this.timer = setInterval(
                () => {
                  // Api
                  LoadImage(
                      // successful request
                      (guestList, hostList) => {
                        this.setImageList(guestList, hostList);
                      }
                  )
                },
                Constants.POLLING_INTERVAL
            )
          })
        }
    )
  }

  setImageList = (guestImageList, hostImageList) => {
    this.setState({
      // if cardsListModalVisible won't update image list
      guestImageList: this.state.cardsListModalVisible
          ? this.state.guestImageList : guestImageList,
      modalList: hostImageList
    })
  }

  componentWillUnmount() {
    // remove interval when sort start or unmount
    clearInterval(this.timer)
  }

  render() {

    const {t} = this.props

    return (
        <>
          <Spin visible={this.state.loading} text={t('common.loading')}/>
          <Spin visible={this.state.logout} text={t('common.logout')}/>
          <LogoutModal
              // logout modal
              show={this.state.logoutModalVisible}
              onHide={() => {
                this.setState({
                  logoutModalVisible: false
                })
              }}
              onConfirm={() => {
                this.setState({
                  logoutModalVisible: false
                })
                LogoutCheck(() => {
                  let reservationId = LoginStorage.getReservationId();
                  // clear session storage
                  LoginStorage.clearStorage()
                  // route to login
                  this.props.history.push({
                    pathname: '/login',
                    // set state["reservationId"]
                    state: {
                      reservationId: reservationId,
                    }
                  })
                }, () => {
                  this.setState({logout: true})
                }, () => {
                  this.setState({logout: false})
                })
              }}
              text={t('common.logout_message')}
          />

          <CardsListModal
              visible={this.state.cardsListModalVisible}
              onHide={() => {
                this.setState({
                  cardsListModalVisible: false
                })
              }}
              modalImageList={this.state.modalList}
          />

          <div className={styles.body}>
            <Toolbar
                // show logout modal
                onLogout={() => {
                  this.setState({
                    logoutModalVisible: true
                  })
                }}
                // show cardsListModal
                onOpenModal={() => {
                  this.setState({cardsListModalVisible: true})
                }}
            />
            <div className={styles.main}>
              <SortableImageContainer
                  currentImageList={this.state.guestImageList}
                  dropZoneVisible={false}
                  enlargeable={true}
                  // the flag of determine modal
                  topZIndex={false}
              />
            </div>
          </div>
        </>
    );
  }
}

export default withTranslation()(SecurityPage(['host'], Index));