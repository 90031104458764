import React, {useEffect, useState} from "react";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import ReactDOM from 'react-dom'
import "./index.css"

// the component of show recent message
class Message extends React.Component {

  render() {

    const {text} = this.props

    return (
        <div className="message" data-testid="test-message">
          <div className="message-content">
            <div className="text">
              {text}
            </div>
          </div>
        </div>
    )
  }
}

// id num
let seed = 0
// date
const now = Date.now()
// key value
const getUuid = () => {
  const id = seed
  seed += 1
  return `MESSAGE_` + now + id
}

// method of add notice
let add;

export const MessageContainer = () => {
  // useState to set notice
  const [notices, setNotices] = useState([])
  // Existence time
  const timeout = 3 * 1000
  // max num of notice
  const maxCount = 5

  // method of remove notice from MessageContainer
  const remove = (notice) => {
    const {key} = notice

    setNotices((prevNotices) => (
        prevNotices.filter(({key: itemKey}) => key !== itemKey)
    ))
  }

  // method of add notice into MessageContainer
  add = (notice) => {
    setNotices((prevNotices) => [...prevNotices, notice])

    // set Existence time
    setTimeout(() => {
      remove(notice)
    }, timeout)
  }

  // Limited number of notices length
  useEffect(() => {
    if (notices.length > maxCount) {
      const [firstNotice] = notices
      remove(firstNotice)
    }
  }, [notices])

  return (

      <div className="message-container">
        <TransitionGroup>
          {
            notices.map(({text, key, type}) => (
                <CSSTransition
                    key={key}
                    timeout={300}
                    classNames="item"
                >
                  <Message type={type} text={text}/>
                </CSSTransition>
            ))
          }
        </TransitionGroup>
      </div>

  )
}

// load dom into body
let el = document.querySelector('#message-wrapper')
if (!el) {
  el = document.createElement('div')
  el.className = 'message-wrapper'
  el.id = 'message-wrapper'
  document.body.appendChild(el)
}

ReactDOM.render(
    <MessageContainer/>,
    el
)

// export api interface
const api = {
  info: (text) => {
    add({
      text,
      key: getUuid()
    })
  }
}

export default api