import React from 'react';
import {LoginStorage} from "../service/GlobalService";
import {Redirect} from "react-router-dom";

// ページを表示する権限をチェックする
const SecurityPage = (roles, Page) => props => {

  if (roles.includes(LoginStorage.getRole())) {
    return <Page {...props} />;
  }

  return <Redirect
      to={{
        pathname: "/error",
        state: {
          message: props.t('error.permission_error'),
        }
      }}
  />
}

export {SecurityPage}