import React, {Component} from 'react';
import {
  CatchBlobImageUrl,
  LoadImage,
  GenerateZipBlob,
  LogoutCheck
} from "../../service/ApiCollection";
import Spin from "../../component/spin/index"
import {SecurityPage} from "../SecurityPage";
import SortableImageContainer from "../../component/gallery-container";
import CardsListModal from "../../component/cards-list-modal";
import Constants from "../../setting/Constants";
import styles from "../CommonStyle.module.css";
import Toolbar from "../../component/toolbar";
import {withTranslation} from "react-i18next";
import Modal from "../../component/common-modal";
import Message from "../../component/message";
import {Download} from "../../service/Utils";
import {LoginStorage} from "../../service/GlobalService";

class Index extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // gallery
      hostImageList: null,
      modalList: [],
      // spin
      loading: false,
      downloading: false,
      logout: false,
      // modal
      cardsListModalVisible: false,
      logoutModalVisible: false,
      downloadModalVisible: false,
    }
    this.multipleChooseImage = []
  }

  componentDidMount() {
    this.setState({
      loading: true
    })

    // send a request
    LoadImage(
        // successful request
        (guestList, hostList) => {
          this.setImageList(guestList, hostList);
        },

        // finally set loading:false
        () => {
          this.setState({
            loading: false
          }, () => {
            // add setInterval (send a request per 5s)
            this.timer = setInterval(
                () => {
                  // Api
                  LoadImage(
                      // successful request
                      (guestList, hostList) => {
                        this.setImageList(guestList, hostList);
                      }
                  )
                },
                Constants.POLLING_INTERVAL
            )
          })
        }
    )
  }

  componentWillUnmount() {
    // remove interval when sort start or unmount
    clearInterval(this.timer)
  }

  setImageList = (guestImageList, hostImageList) => {
    this.setState({
      // if cardsListModalVisible won't update image list
      hostImageList: this.state.cardsListModalVisible
          ? this.state.hostImageList : hostImageList,
      modalList: guestImageList
    })
  }

  // get multipleChoose
  getMultipleChoose = chooseImageList => {
    this.multipleChooseImage = chooseImageList
  }

  // 選択した名刺をダウンロード
  downloadClick = async () => {
    this.setState({
      downloading: true
    })

    // if multipleChooseImage equals 1,download image
    if (this.multipleChooseImage.length === 1) {
      CatchBlobImageUrl(
          this.multipleChooseImage[0],
          res => {
            Download(res, 'card.jpg');
          },
      )
    }
    // if multipleChooseImage more than 1,download zip
    else if (this.multipleChooseImage.length > 1) {
      try {
        const zipBlob = await GenerateZipBlob(this.multipleChooseImage);
        Download(URL.createObjectURL(zipBlob), 'card.zip');
      } catch (error) {
        Message.info(this.props.t("common.download_failed"));
      }
    }
    this.setState({
      downloading: false
    })
  }

  render() {

    const {t} = this.props

    return (
        <>
          <Spin visible={this.state.loading} text={t('common.loading')}/>
          <Spin visible={this.state.downloading}
                text={t('common.downloading')}/>
          <Spin visible={this.state.logout} text={t('common.logout')}/>

          <Modal
              // logout modal
              show={this.state.logoutModalVisible}
              onHide={() => {
                this.setState({
                  logoutModalVisible: false,
                })
              }}
              onConfirm={() => {
                this.setState({
                  logoutModalVisible: false
                })
                LogoutCheck(() => {
                  let reservationId = LoginStorage.getReservationId();
                  // clear session storage
                  LoginStorage.clearStorage()
                  // route to login
                  this.props.history.push({
                    pathname: '/login',
                    // set state["reservationId"]
                    state: {
                      reservationId: reservationId,
                    }
                  })
                }, () => {
                  this.setState({logout: true})
                }, () => {
                  this.setState({logout: false})
                })
              }}
              text={t('common.logout_message')}
          />

          <Modal
              // download dialog
              show={this.state.downloadModalVisible}
              onHide={() => {
                this.setState({
                  downloadModalVisible: false
                })
              }}
              onConfirm={() => {
                this.setState({
                  downloadModalVisible: false
                }, () => {
                  // download image
                  this.downloadClick().then()
                })
              }}
              text={t('common.download_message')}
          />

          <CardsListModal
              visible={this.state.cardsListModalVisible}
              onHide={() => {
                this.setState({
                  cardsListModalVisible: false
                })
              }}
              modalImageList={this.state.modalList}
          />

          <div className={styles.body}>
            <Toolbar
                onDownload={() => {
                  // show modal while selected image over 0
                  if (this.multipleChooseImage.length > 0) {
                    this.setState({
                      downloadModalVisible: true
                    })
                  }
                }}
                // show logout modal
                onLogout={() => {
                  this.setState({
                    logoutModalVisible: true
                  })
                }}
                // show cardsListModal
                onOpenModal={() => {
                  this.setState({
                    cardsListModalVisible: true
                  })
                }}
            />
            <div className={styles.main}>
              <SortableImageContainer
                  currentImageList={this.state.hostImageList}
                  dropZoneVisible={true}
                  setMultipleChoose={this.getMultipleChoose}
                  enlargeable={true}
                  // the flag of determine modal
                  topZIndex={false}
              />
            </div>
          </div>
        </>
    );
  }
}

export default withTranslation()(SecurityPage(['guest'], Index));