import {Col, Container, Row} from "react-bootstrap";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {ArrayMove} from "../../service/Utils";
import GalleryImage from "../gallery-image";
import styles from "./index.module.css"
import Dropzone from "../dropzone";
import {getI18n} from "react-i18next";
import CloseDropZoneModal from "../common-modal";
import Message from "../message";

// The container component of drag cards and display cards thumbnails

// Element Container
const SortableImageContainer = SortableContainer(
    ({children}) => children
);

// Element Component
const SortableImage = SortableElement((props) => {
  const {imageSrc, selected, onSelect, enlargeable, topZIndex} = props;
  // convert sortableElement to image
  return (
      <Col xs={12} md={6} lg={4}
           className={`${topZIndex ? styles.noneEventWithZIndex
               : styles.noneEvent} ${styles.colXl3}`}>
        <GalleryImage
            // image Url
            imageSrc={imageSrc}
            selected={selected}
            onSelect={onSelect}
            enlargeable={enlargeable}
        />
      </Col>
  )
});

export default ({currentImageList, dropZoneVisible, topZIndex, setMultipleChoose, enlargeable}) => {

  // package currentImageList
  const [imageList, setImageList] = useState([]);
  // dropzone visible
  const [dropUploadAreaVisible, setDropUploadAreaVisible] = useState(
      dropZoneVisible);
  const [closeDropZoneModalVisible, setCloseDropZoneModalVisible] = useState(
      false);
  const [sorting, setSorting] = useState(false)
  // get click image times in 150ms
  let clickTimes = useRef(0);
  // the window timeout
  let clickDelayTimer = useRef(null);
  // multiple choose collection
  const multipleChooseImage = useRef([]);

  const previousImageList = useRef(null);

  useEffect(() => {

    // render Flag
    let imageChangeFlag = false;

    if (!sorting && currentImageList) {
      // get upload image
      for (let imageItemIndex in imageList) {
        for (let currentImageItemIndex in currentImageList) {
          // update imageList
          if (currentImageList.hasOwnProperty(currentImageItemIndex)
              && imageList[imageItemIndex].key
              === currentImageList[currentImageItemIndex].key) {
            if (imageList[imageItemIndex].url
                !== currentImageList[currentImageItemIndex].url) {
              imageList[imageItemIndex].url = currentImageList[currentImageItemIndex].url
              imageChangeFlag = true;
            }
            break
          }
        }
      }

      // get increaseImageList
      const increaseImageList = currentImageList.filter(
          item => !imageList.map(imageItem => imageItem.key).includes(item.key)
      )

      if (increaseImageList.length > 0) {
        //　画像初期表示時画像追加メッセージを出さない
        // !!!options must be count
        if (previousImageList.current != null) {
          Message.info(getI18n().t('common.increased_card_message',
              {count: increaseImageList.length}))
        }
      }

      // Arrange the uploaded pictures to the end
      if (increaseImageList.length > 0) {
        imageChangeFlag = true;
      }

      // if imageList have chang, setImageList
      if (imageChangeFlag) {
        setImageList(imageList.concat(increaseImageList))
      }

    }
  }, [currentImageList, imageList, sorting]);

  // Store current value in ref
  useEffect(() => {
    previousImageList.current = currentImageList;
  }, [currentImageList]);

  // distinguish single click or double click
  // selectedImageItem.url(normal Url)
  const onClickImage = (event, selectedImageItem, currentIndex) => {
    clickTimes.current++
    // clear timer if last timer didn't  effect
    if (clickDelayTimer.current) {
      clearTimeout(clickDelayTimer.current)
      clickDelayTimer.current = null;
    }
    // react event to js event
    event.persist()
    // set timer
    clickDelayTimer.current = setTimeout(() => {
      // onClick event
      onSelect(event, selectedImageItem, currentIndex)
      clickTimes.current = 0
    }, 300)
    // onDoubleClickEvent
    if (clickTimes.current === 2) {
      clearTimeout(clickDelayTimer.current)
      clickTimes.current = 0
    }
  }

  // selectedImageItem.key was the initial index
  const onSelect = (event, selectedImageItem, currentIndex) => {
    let newImageList;
    // onSelected
    if (event.ctrlKey || event.metaKey) {
      // set image selected(effect css style)
      newImageList = imageList.map((item, index) => {
        if (index === currentIndex) {
          item.selected = !item.selected
          return item
        } else {
          return item
        }
      })
    }
    // onClick
    else {
      // reset selected
      newImageList = imageList.map((item, index) => {
        if (index === currentIndex) {
          item.selected = !item.selected
          return item
        } else {
          item.selected = false
          return item
        }
      })
    }
    // provide multipleChooseImage to parent Component
    multipleChooseImage.current = imageList.filter(item => item.selected).map(
        item => item.url)
    setImageList(newImageList);
    setMultipleChoose && setMultipleChoose(multipleChooseImage.current)
  }

  const pictures = useMemo(() => imageList.map((imageItem, index) => {
    return (
        <SortableImage
            index={index}
            imageSrc={imageItem.url}
            selected={imageItem.selected}
            enlargeable={enlargeable}
            onSelect={ev => onClickImage(ev, imageItem, index)}
            key={imageItem.key}
            topZIndex={topZIndex}
        />
    );
  }), [imageList]);// eslint-disable-line react-hooks/exhaustive-deps

  // start sort(mouseDown)
  const onSortStartCallback = useCallback(
      (_, event) => {
        event.preventDefault()
        setSorting(true);
      },
      [setSorting],
  );

  // end sort(mouseUp)
  const onSortEndCallback = useCallback(
      ({oldIndex, newIndex}) => {
        // reset selected
        imageList.map(item => {
          item.selected = false
          return item
        })
        setSorting(false);
        multipleChooseImage.current = []
        // clear selected image after sort end
        setImageList(ArrayMove(imageList, oldIndex, newIndex));
        setMultipleChoose && setMultipleChoose(multipleChooseImage.current)
      },
      [imageList, setSorting, setMultipleChoose]
  );

  const onClickContainer = event => {
    // clicking on the non-image area will clear the multiple selection
    if (!event.target.src) {
      // reset selected
      let newImageList = imageList.map(item => {
        item.selected = false
        return item
      })
      setImageList(newImageList);
      multipleChooseImage.current = []
      setMultipleChoose && setMultipleChoose(multipleChooseImage.current)
    }
  }

  return <>

    <CloseDropZoneModal
        show={closeDropZoneModalVisible}
        onHide={() => {
          setCloseDropZoneModalVisible(false)
        }}
        onConfirm={() => {
          setCloseDropZoneModalVisible(false)
          setDropUploadAreaVisible(false)
        }}
        text={getI18n().t('common.close_drop_message')}
    />

    <Container fluid={true} className={styles.fluidGallery}
               onClick={onClickContainer}>
      <SortableImageContainer
          // sort style
          axis={"xy"}
          onSortStart={onSortStartCallback}
          // The callback function is triggered after the drag is over
          onSortEnd={onSortEndCallback}
          distance={3}
      >
        <Row>
          {dropUploadAreaVisible &&
          <Col xs={12} md={6} lg={4} className={styles.colXl3}>
            <div className={styles.dropzoneContainer}>
              <Dropzone onClickCloseIcon={() => {
                setCloseDropZoneModalVisible(true)
              }}/>
            </div>
          </Col>
          }
          {pictures}
        </Row>
      </SortableImageContainer>
    </Container>
  </>;
}
