import React, {useCallback} from "react";
import styles from "./index.module.css"
import {LogOutIcon, DownLoadIcon, ListIcon,LgCloseIcon} from "../icon/Icon";

// ツールバー
export default ({onLogout, onDownload, onOpenModal, onClose}) => {

  const logout = useCallback(() => {
    onLogout && onLogout()
  }, [onLogout])

  const download = useCallback(() => {
    onDownload && onDownload()
  }, [onDownload])

  const openModal = useCallback(() => {
    onOpenModal && onOpenModal()
  }, [onOpenModal])

  const close = useCallback(() => {
    onClose && onClose()
  },[onClose])

  return (
      <>
        <div className={styles.toolbarContainer}>
          <div className={styles.toolbar}>
            {
              // logout icon
              onLogout && <div onClick={logout}
                               className={`${styles.icon} ${styles.closeIcon}`}>
                <LogOutIcon/>
              </div>
            }
            {
              // download icon
              onDownload && <div onClick={download}
                                 className={`${styles.icon} ${styles.downLoadIcon}`}>
                <DownLoadIcon/>
              </div>
            }
            {
              // menu icon
              onOpenModal && <div onClick={openModal}
                                  className={`${styles.icon} ${styles.listIcon}`}>
                <ListIcon/>
              </div>
            }
            {
              // exit icon
              onClose && <div onClick={close}
                               className={`${styles.icon} ${styles.closeIcon}`}>
                <LgCloseIcon/>
              </div>
            }
          </div>
        </div>
      </>
  )
}