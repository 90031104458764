import React from "react";
import Home from './page/home';
import Error from './page/error';
import Login from './page/login';
import Guest from './page/guest-photo-gallery';
import Host from './page/host-photo-gallery';
import Upload from './page/mobile-upload';
import History from "./RouteHistory.js";
import {Router} from "react-router-dom";

import {
  Switch,
  Route
} from "react-router-dom";

export default function App() {
  return (
      <Router history={History}>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/login" component={Login}/>
          <Route path="/guest" component={Guest}/>
          <Route path="/host" component={Host}/>
          <Route path="/upload" component={Upload}/>
          <Route component={Error}/>
        </Switch>
      </Router>
  );
}