import Constants from "../setting/Constants";

// Move the elements inside the array and generate a new array
const ArrayMove = (arr, previousIndex, newIndex) => {
  const array = arr.slice(0)

  if (newIndex === -1) {
    // delete element
    array.splice(previousIndex, 1)
  } else {
    // add undefined element while newIndex>array.length
    if (newIndex >= array.length) {
      let k = newIndex - array.length

      while (k-- + 1) {
        array.push(undefined)
      }
    }
    // set new array
    array.splice(newIndex, 0, array.splice(previousIndex, 1)[0])
  }

  return array
}

// 300dpi固定に変更する
const ResetBlobDpi = blob => {
  // to contain the information.
  const headerChunk = blob.slice(0, 33);
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const dataArray = new Uint8Array(fileReader.result);
      const tail = blob.slice(33);
      // change dpi to 300
      let dpi = 300
      // 1 pixel per inch or 2 pixel per cm
      dataArray[13] = 1;
      // dpiX high byte
      dataArray[14] = dpi >> 8;
      // dpiX low byte
      dataArray[15] = dpi & 0xff;
      // dpiY high byte
      dataArray[16] = dpi >> 8;
      // dpiY low byte
      dataArray[17] = dpi & 0xff;
      resolve(new Blob([dataArray, tail], {type: blob.type}));
    };
    fileReader.onerror = () => {
      reject();
    }
    fileReader.readAsArrayBuffer(headerChunk);
  });
}

// 画像の縦横比は変更しなくで、長辺は1075以下に縮小する。
const Compress = file => {
  // max upload size
  const MAX_SIZE = Constants.LARGEST_FILE
  return new Promise((resolve, reject) => {
    // new image
    let img = new Image();
    img.src = URL.createObjectURL(file);
    // image size scaling ratio
    let imageScale = 1;

    // after load create canvas, compress Image by canvas
    img.onload = () => {
      let canvas = document.createElement('canvas');
      let imageWidth = img.width
      let imageHeight = img.height
      let maxLength = 1075

      if (imageWidth >= maxLength || imageHeight >= maxLength) {
        let widthScale = maxLength / imageWidth
        let heightScale = maxLength / imageHeight
        imageScale = widthScale > heightScale ? heightScale : widthScale
      }

      // image width and height; we can compress by width or height
      canvas.width = imageWidth * imageScale;
      canvas.height = imageHeight * imageScale;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, imageWidth * imageScale,
          imageHeight * imageScale);

      canvas.toBlob(blob => {
        // Determine whether the file is larger than MAX_SIZE(4MB)
        if (blob.size < MAX_SIZE) {
          resolve(blob)
        }
        // 4Mb以上であればエラーとしてする
        else {
          reject()
        }
      }, 'image/jpeg', 0.9);
    }
    img.onerror = () => {
      reject()
    }
  })
}

// check Image
const CheckImageType = (file, onSuccess, onFailed) => {
  // check file extension
  if (file.type === "image/jpeg") {
    // get file ArrayBuffer
    let fr = new FileReader();
    fr.readAsArrayBuffer(file);
    // onSuccess
    fr.onload = event => {
      let result = event.target.result
      let uint8 = new Uint8Array(result)
      // check uint8Array of file start with 255,216,255
      if (uint8[0] === 255 && uint8[1] === 216 && uint8[2] === 255) {
        onSuccess(file)
      } else {
        onFailed()
      }
    }
    // onError
    fr.onerror = () => {
      onFailed()
    }
  }
  // the file MIME is not included in image types or can't discern
  else {
    onFailed()
  }
}

const Download = (url, name) => {
  // user a.download to load Image
  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();
}

const getQueryParam = key => {
  if (window.location.search) {
    // remove '?'
    const urlParams = window.location.search.slice(1);
    // get all params
    let paramList = urlParams.split("&")
    // get target param
    let targetParam = paramList.filter(item => item.startsWith(key + '='))
    if (targetParam[0]) {
      return targetParam[0].split("=")[1]
    } else {
      return undefined
    }
  }
  return undefined
}

export {
  ArrayMove,
  Compress,
  CheckImageType,
  Download,
  getQueryParam,
  ResetBlobDpi
}