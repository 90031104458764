import React, {useEffect, useState, useRef} from "react";
import {GetImageBlobUrl} from "../../service/ApiCollection";
import {Image, Spinner} from "react-bootstrap";
import styles from "../gallery-image/index.module.css";
import NewWindow from "../new-window";
import {CatchBlobImageUrl} from "../../service/ApiCollection";

// the container component of image
export default ({imageSrc, selected, enlargeable, onSelect}) => {
  const [imageBlobUrl, setImageBlobUrl] = useState("");
  const [imageLoading, setImageLoading] = useState(true);
  // new window showFlag
  const [windowVisible, setWindowVisible] = useState(false);
  // the clickImage url
  const [windowImageUrl, setWindowImageUrl] = useState("");
  // new window ref
  const childWindow = useRef();
  useEffect(() => {
    // Download image to cache
    GetImageBlobUrl(
        imageSrc,
        (blobUrl) => {
          setImageBlobUrl(blobUrl)
        },
        () => {
          // reset image src
          setImageBlobUrl("failed")
        },
        () => {
          setImageLoading(false)
        }
    )
  }, [imageSrc])

  return <>
    {
      // window.open
      windowVisible &&
      <NewWindow ref={childWindow} features={{height: 701, width: 700}}
                 onUnload={() => {
                   setWindowVisible(false)
                 }}>
        <div className={styles.windowContainer}>
          <Image className={styles.windowImage} src={windowImageUrl}/>
        </div>
      </NewWindow>
    }
    <div className={styles.imageContainer}>

      {
        imageLoading ? <div className={styles.spinner}>
              <Spinner
                  animation="border" variant="primary"
              />
            </div> :
            <Image
                // Browser compatible (check firefox after change)!!!
                onDragStart={event => {
                  event.preventDefault();
                }}
                className={`${styles.image} ${selected ? styles.selectedBorder
                    : styles.initialBorder}`}
                src={imageBlobUrl}
                onClick={onSelect}
                onDoubleClick={() => {
                  if (enlargeable) {
                    if (childWindow.current) {
                      childWindow.current["window"].focus();
                    } else {
                      setWindowVisible(true)
                      CatchBlobImageUrl(imageSrc, blobUrl => {
                        setWindowImageUrl(blobUrl)
                      }, () => {
                        setWindowImageUrl("failed")
                      })
                    }
                  }
                }}
            />
      }

    </div>
  </>

}