import React, {useCallback, useMemo} from "react";
import {CloseIcon} from "../icon/Icon";
import SortableImageContainer from "../gallery-container";
import {Modal} from "react-bootstrap";
import styles from "./index.module.css"
import "./index.css"
import {useTranslation} from "react-i18next";

// The modal component of show the gallery that belong to the same role
export default ({visible, onHide, modalImageList}) => {
  const {t} = useTranslation()

  // return gallery container
  const ImageContainer = useMemo(() => {
    return (
        <SortableImageContainer
            currentImageList={modalImageList}
            dropZoneVisible={false}
            // the flag of determine modal
            topZIndex={true}
            enlargeable={false}
        />
    )
  }, [modalImageList])

  // close modal
  const onClose = useCallback(() => {
    onHide && onHide()
  }, [onHide])

  return (
      <Modal show={visible} dialogClassName="modal-95w" onHide={onClose}
             contentClassName="cards-list-modal-content">
        <Modal.Body>
          <div className={styles.closeIcon} onClick={onClose}>
            <CloseIcon/>
          </div>
          <div className={styles.galleryContainer}>
            <div className={styles.title}>
              {t('common.card_list_modal_title')}
            </div>
            <div className={styles.main}>
              {ImageContainer}
            </div>
          </div>
        </Modal.Body>
      </Modal>
  )
}